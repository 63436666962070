import React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Breakpoints from '../../Abstracts/Breakpoints';
import Spacing from '../../Abstracts/Spacing';

interface PageSectionProps {
    children: ReactNode;
    full?: boolean;
}

export default function PageSection(props: PageSectionProps) {
    return (
        <StyledPageSection full={props.full}>
            {props.children}
        </StyledPageSection>
    )
}

const StyledPageSection = styled.div`
    ${(props: {full?: boolean}) => `
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        ${props.full 
            ? "min-height: 100vh;" 
            : ""
        }
        padding: ${Spacing.Medium};

        @media(min-width: ${Breakpoints.Medium}) {
            padding: ${Spacing.Large};
        }

        > * {
            max-width: ${Breakpoints.Large};
        }
    `}

`;