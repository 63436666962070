import React, { useEffect } from 'react';
import styled from 'styled-components';
import Breakpoints from '../../Abstracts/Breakpoints';
import Spacing from '../../Abstracts/Spacing';

interface SideProjectsProps {

}

export default function SideProjects(props: SideProjectsProps) {
    
    useEffect(() => {
    
    }, [])
    
    return (
        <StyledSideProjects>
            <h2>Side Projects</h2>
            <StyledImageSection>
                <img src="limajuice.png" alt="Limajuice logo"/>
                <section>
                    <a href="https://limajuice.com"><h3>Limajuice</h3></a>
                    <p>An ongoing series of games and side projects with another developer. These have all been fun side projects often with the goal of learning new things, such as:</p>
                    <ul>
                        <li>Creating a real time card game using Websockets (SignalR,)</li>
                        <li>Creating and deploying multiple single page applications on a CDN (using AWS), and</li>
                        <li>Running a local Raspberry Pi Kubernetes cluster used for hosting our Web APIs and services.</li>
                    </ul>
                </section>
            </StyledImageSection>
            <StyledImageSection>
                <img src="drilldata.png" alt="The logo used for our android application"/>
                <section>
                    <h3>TouchData</h3>
                    <p>A partnership between myself, a mining engineer and another developer, where we developed an android application for use in streamling data collection in the mining industry. 
                        While this venture isn't being actively pursued, the following were some key milestones:</p>
                    <ul>
                        <li>Starting a business and partnership,</li>
                        <li>Creating and deploying a production android application and an accompanying desktop application, and</li>
                        <li>Trialing the application at a mine site in New Zealand where it has now been in active use for several years.</li>
                    </ul>
                </section>

            </StyledImageSection>
        </StyledSideProjects>
    )
}

const StyledImageSection = styled.section``;

const StyledSideProjects = styled.div`
    width: 100%;

    ${StyledImageSection} {
        display: flex;
        width: 100%;
        img {
            width: clamp(32px, 25vw, 200px);
            height: clamp(32px, 25vw, 200px);
        }

        > * {
            margin-bottom: ${Spacing.Small};
        }

        @media(max-width: ${Breakpoints.Small}) {
            flex-direction: column;
            h3 {
                margin-top: 0;
            }
        }
    }
`;