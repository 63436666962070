import React from 'react';
import styled from 'styled-components';
import { AnimationTiming } from '../../Abstracts/Animations';

interface ButtonProps {
    children: string;
    action?: () => void;
    href?: string; 
}

export default function Button(props: ButtonProps) {
    if (props.href) {
        return <StyledLinkButton onClick={props.action} href={props.href}>{props.children}</StyledLinkButton>
    }
    
    return <StyledButton onClick={props.action}>{props.children}</StyledButton>;
}

const CommonStyles = `
    border: 2px solid #9DC88D;
    && {
        color: #666666;
    }

    border-radius: 8px;
    padding:16px;
    background: none;

    :hover {
        background-color: rgba(157, 200, 141, 0.4);
    }
    transition: background 200ms ${AnimationTiming.Productive};
`

const StyledButton = styled.button`
    ${CommonStyles}
`;

const StyledLinkButton = styled.a`
    display: inline-block;
    text-decoration: none;
    ${CommonStyles}
`;