import React, { useEffect } from 'react';
import styled from 'styled-components';
import Spacing from '../../Abstracts/Spacing';
import Button from '../Common/Button';

interface AboutProps {

}

export default function About(props: AboutProps) {
    
    useEffect(() => {
    
    }, [])
    
    return (
        <StyledAbout>
                <h1>Hayden Steel</h1>
                <p>I'm a Brisbane-based software engineer and full stack developer with a passion for writing clean and performant code. 
                    From starting my own business to working in software development companies, I have been building web applications across a variety of tech stacks for over five years. 
                </p>
                <Button href="mailto:haydensteel.dev@gmail.com">Contact Me</Button>
        </StyledAbout>
    )
}

const StyledAbout = styled.div`
    max-width: 1000px;
    h1 {
        
        font-size: clamp(2rem, 6vw, 4rem);
        margin: 0.5em 0;
    }
    p {
        max-width: 600px;
        font-size: clamp(1rem, 2vw, 1.2rem);
        margin-bottom: ${Spacing.Medium};
    }

`;