import React from 'react';
import styled from 'styled-components';
import About from './Pages/About';
import PageSection from './Common/PageSection';
import Skills from './Pages/Skills';
import Employment from './Pages/Employment';
import SideProjects from './Pages/SideProjects';
import Education from './Pages/Education';

interface AppProps {

}

export default function App(props: AppProps) {
    const sections = [
        {content: <About/>, full: true}, 
        {content: <Skills/>},
        {content: <Employment/>},
        {content: <Education />},
        {content: <SideProjects/>},
    ]
    
    return (
        <StyledApp>
               {sections.map((section, i) => <PageSection key={i} full={section.full}>{section.content}</PageSection>)}
        </StyledApp>
    )
}

const StyledApp = styled.div`
    color: #333333;
    > :nth-child(odd) {
        background-color: #EEEEEE;
    }
`;