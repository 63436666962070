import React, { useEffect } from 'react';
import styled from 'styled-components';

interface SkillsProps {

}

export default function Skills(props: SkillsProps) {
    
    useEffect(() => {
    
    }, [])
    
    return (
        <StyledSkills>
            <h2>Skills</h2>
            <p>Experience in creating, maintaining and deploying web applications. This includes creating interactive front-ends, 
                Web APIs, database schemas and deploying to multiple cloud providers.
            </p>
            <h3>Languages</h3>
            <ul>
                <li>C#</li>
                <li>Java</li>
                <li>Javascript + Typescript</li>
                <li>HTML + CSS</li>
                <li>PHP</li>
            </ul>
            <h3>Technologies / Frameworks</h3>
            <ul>
                <li>React (with state management Redux/mobx)</li>
                <li>.Net Core + ASP.NET + EF Core</li>
                <li>SpringBoot</li>
                <li>Relational Databases (Mysql, PostreSQL, MSSQL)</li>
                <li>Docker</li>
                <li>Kubernetes</li>
                <li>Modelling and Code Generation using EMF</li>
                <li>AWS + Azure</li>
            </ul>
        </StyledSkills>
    )
}

const StyledSkills = styled.div`
`;