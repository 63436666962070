import React, { useEffect } from 'react';
import styled from 'styled-components';

interface EducationProps {

}

export default function Education(props: EducationProps) {
    
    useEffect(() => {
    
    }, [])
    
    return (
        <StyledEducation>
            <h2>Education & Awards</h2>
            <StyledEducationRow>
                <h3>The University of Queensland</h3>
                <span>Graduated 2017</span>
            </StyledEducationRow>
            <p>Bachelor of Engineering - Honours Class 1 with a Major in Software Engineering</p>

            <StyledEducationRow>
                <h4>GBST Scholarship in Software Engineering</h4>
                <span>2015</span>
            </StyledEducationRow>
            <StyledEducationRow>
                <h4>ICT Excellence Scholarship</h4>
                <span>2014</span>
            </StyledEducationRow>
        </StyledEducation>
    )
}

const StyledEducationRow  = styled.header`
`;

const StyledEducation = styled.div`
    width: 100%;
    h3 {
        margin-bottom: 0;
    }

    ${StyledEducationRow} {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            margin-left: 8px;
        }
        span {
            flex-shrink: 0;
        }
    }
`;