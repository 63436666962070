import React, { useState } from "react";
import styled from "styled-components";
import EmploymentItem from "./EmploymentItem";

interface EmploymentProps {}

export default function Employment(props: EmploymentProps) {
  const [expandedItemIndex, setExpandedItemIndex] = useState<number>(0);

  const employmentItems = [
    {
      name: "Codebots",
      startYear: 2018,
      description: (
        <>
          <p>
            Responsible for developing, releasing and maintaining new features
            of the Codebots product. Gained experience working in an agile team
            across several services written in different technologies. Key
            experiences:
          </p>
          <ul>
            <li>
              <p>
                Work in a product team to launch a web platform that utilises
                modelling and code generation techniques to help other software
                teams start projects.
              </p>
              <BoldText>
                (LAMP, Javascript, Java, Eclipse Code Generation Tools, AWS)
              </BoldText>
            </li>
            <li>
              <p>
                Heavily involved in modernisation projects of our existing
                services. This includes replacing or upgrading several frontend
                and backend services.
              </p>
              <BoldText>
                (Typescript, React, .NET Core, Docker, Kubernetes, Azure)
              </BoldText>
            </li>
            <li>
              <p>
                Taking a leadership role and running a small agile team, as well
                as mentoring new members of the team.
              </p>
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "WorkingMouse",
      startYear: 2016,
      endYear: 2018,
      description: (
        <>
          <p>
            Scoping and developing web and mobile software solutions for a
            variety of clients across a range of industries. Here I worked as a
            full stack developer and gained experience working closely with
            clients.
          </p>
          <BoldText>(LAMP, Javascript, Java)</BoldText>
        </>
      ),
    },
    {
      name: "HealthCareLine",
      startYear: 2014,
      endYear: 2016,
      description: (
        <>
          <p>
            Part of a small team and lead developer responsible for enabling
            organisations to digitise their forms and processes. My primary role
            was creating the replacement of a legacy PHP system in .NET.
          </p>
          <BoldText>
            (.NET MVC, HTML, CSS, Javascript, SQL SERVER, AZURE)
          </BoldText>
        </>
      ),
    },
    {
      name: "GBST Internship",
      startYear: 2015,
      endYear: 2016,
      description: (
        <>
          <p>
            Intern software developer over the university vacation period.
            Experience with Java and Groovy languages and exposure to software
            development in the financial sector.
          </p>
          <BoldText>(Java, Groovy, JavaFX, Gradle)</BoldText>
        </>
      ),
    },
    {
      name: "Locatrix Communications Internship",
      startYear: 2014,
      endYear: 2015,
      description: (
        <>
          <p>
            Part time work experience over the university vacation period.
            Exposure to app and web development including creating a proof of
            concept iOS application and working with bluetooth beacons.
          </p>
          <BoldText>
            (IOS Development, Objective C, PHP, Heroku, Bluetooth beacons)
          </BoldText>
        </>
      ),
    },
  ];

  return (
    <StyledEmployment>
      <h2>Employment</h2>
      {employmentItems.map((item, index) => (
        <EmploymentItem
          {...item}
          key={index}
          expanded={expandedItemIndex === index}
          onExpand={() => setExpandedItemIndex(index)}
        />
      ))}
    </StyledEmployment>
  );
}

const BoldText = styled.p`
  font-weight: bold;
`;

const StyledEmployment = styled.div`
  width: 100%;
`;
