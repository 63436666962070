import React, { useEffect,  useRef, useState } from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Spacing from '../../Abstracts/Spacing';

export interface EmploymentItemProps {
    startYear: number;
    endYear?: number;
    name: string;
    description: ReactNode;
    expanded: boolean;
    onExpand: () => void;
}

export default function EmploymentItem(props: EmploymentItemProps) {
    const {name, expanded, description} = props;
    const descriptionRef = useRef<HTMLDivElement>(null);
    const [descriptionHeight, setDescriptionHeight] = useState<number>();

    useEffect(() => {
		if (!descriptionRef?.current) {
            return;
        }

		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.contentBoxSize && descriptionRef.current) {
                    setDescriptionHeight(descriptionRef.current.clientHeight);
				}
			}
		});

		resizeObserver.observe(descriptionRef.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, [descriptionRef]);


    return (
        <StyledEmploymentItem expanded={expanded} onClick={props.onExpand}>
            <StyledEmploymentItemHeader>
                <h3>{name}</h3>
                <StyledEmploymentDate>{props.startYear} - {props.endYear ?? "Current"}</StyledEmploymentDate>
                {expanded === false ? <i className={`fas fa-chevron-circle-down`}></i> : null }
            </StyledEmploymentItemHeader>
            <DescriptionAnimationContainer height={expanded ? descriptionHeight : 0}>
                <StyledEmploymentDescription className={expanded ? "expanded": "" } ref={descriptionRef}>
                    {description}
                </StyledEmploymentDescription>
            </DescriptionAnimationContainer>
        </StyledEmploymentItem>
    )
}

const StyledEmploymentItemHeader = styled.header``;
const StyledEmploymentDescription = styled.header`
    display: flex;
    flex-direction: column;
`;
const StyledEmploymentDate = styled.span``;
const StyledEmploymentItem = styled.div`
    ${(props: {expanded: boolean}) => `
        cursor: pointer;
        border-bottom: 1px solid #999999;
        
        :last-child {
            border: none;
        }
        ${StyledEmploymentItemHeader} {
            display: flex;
            align-items: center;

            i {
                color: #999999;
            }

            ${StyledEmploymentDate} {
                margin-left: auto;
                margin-right: ${props.expanded ? Spacing.Medium : Spacing.Small};
            }
        }
        
        ${StyledEmploymentDescription} {
            margin-right: ${Spacing.Small};
        }
    `}
`;

const DescriptionAnimationContainer = styled.div<{ height: number | undefined }>`
	${(props) => (props.height ? `max-height: ${props.height}px; min-height: ${props.height}px;` : '')}
	transition: max-height 300ms, min-height 300ms;
	overflow: hidden;
    min-height: ${props => props.height};
    max-height: ${props => props.height};
`;

